.card {
  width: 100%;
  position: relative;
}

.card img {
  width: 100%;
  height: auto;
}

.card .text-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 18px 20px;
  display: flex;
  justify-content: space-between;
}

.card .text {
  color: #FFF;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: -0.16px;
}

@media only screen and (min-width: 600px) {
  .card .text-container {
    padding: 24px 27px;
  }

  .card .text {
    font-size: 18px;
    line-height: normal;
    letter-spacing: -0.18px;
  }
}