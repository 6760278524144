.home {
  flex: 1 1 100%;
  width: 100%;
  padding: 26px 22px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home__image-wrapper {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  margin-bottom: 44px;
}

.home__image-wrapper img {
  width: 100%;
}

.home__text-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 21px;
}

.home__text-container p {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
}

.home__text-container p a {
  color: #000;
  text-decoration: none;
}

.home__text-container p span {
  display: inline;
}

@media only screen and (min-width: 450px) {
	.home__image-wrapper {
    width: 300px;
  }
}

@media only screen and (min-width: 500px) {
  .home__text-container p span {
    display: inline-block;
  }
}

@media only screen and (min-width: 650px) {
	.home__text-container {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }

  .home__max-width-paragraph {
    max-width: 600px;
  }

  .home__no-wrap-paragraph {
    white-space: nowrap;
  }
}

@media only screen and (min-width: 768px) {
  .home {
    padding: 28px 26px;
  }

  .home__text-container p span {
    display: block;
  }
}

@media only screen and (min-width: 1000px) {
  .home__text-container p {
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.2px;
  }
}