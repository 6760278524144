.header {
  position: relative;
  padding: 16px 22px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

.header--white-text .header__nav__link {
  color: rgb(255, 255, 255);
}

.header__background--yellow {
  background: rgb(211, 255, 85, 0.95);
}

.header__background--lightgreen {
  background: rgb(223, 238, 205, 0.95);
}

.header__background--orange {
  background: rgb(255, 198, 113, 0.95);
}

.header__background--brown {
  background: rgb(136, 83, 61, 0.95);
}

.header__background--black,
.header__background--dog {
  background: rgb(36, 36, 36, 0.95);
}

.header__background--pink {
  background: rgb(255, 208, 208, 0.95);
}

.header__background--white {
  background: rgb(241, 249, 255, 0.95);
}

.header__nav {
	flex: 1 1 100%;
	display: flex;
	justify-content: space-between;
}

.header__nav__link-container {
	display: flex;
	gap: 16px;
}

.header__nav__link {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: -0.16px;
	text-decoration: none;
}

.header--fixed {
  opacity: 0;
  position: fixed;
  top: 0;
  transform: translateY(-100%);
  transition: transform ease-in-out 300ms, opacity ease-in-out 50ms;
  z-index: 9999999;
}

.header--fixed-show {
  opacity: 1;
}

.header--fixed-slide {
  transform: translateY(0);
}

.header__background {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(4px);
  z-index: -1;
  pointer-events: none;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 20px 26px;
  }
  
  .header__nav__link-container {
    display: flex;
    gap: 29px;
  }

  .header__nav__link {
    font-size: 18px;
    line-height: 27px;
    letter-spacing: -0.18px;
  }
}