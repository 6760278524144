.carousel {
  width: 100%;
  overflow: hidden;
  padding: 0 24px;
  position: relative;
}

.carousel__container {
  display: flex;
  gap: 24px;
}

.carousel__slide {
  flex: 0 0 491px;
  width: 491px;
  position: relative;
}

.embla__button {
  position: absolute;
  z-index: 1;
  top: 0;
  bottom: 0;
  border: 0;
  width: 140px;
  opacity: 0;
}

.embla__button:disabled {
  pointer-events: none;
  cursor: pointer;
}

.embla__button--prev {
  cursor: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.0598e-06 35C4.74968e-06 15.67 15.67 -4.74968e-06 35 -3.0598e-06C54.33 -1.36992e-06 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 1.36992e-06 54.33 3.0598e-06 35Z' fill='white' fill-opacity='0.8'/%3E%3Cpath d='M37.332 43.3334L28.9987 35L37.332 26.6667' stroke='black' stroke-width='3.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") 16 16, w-resize;
  left: 0;
}

.embla__button--next {
  cursor: url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath transform='translate(70,0) scale(-1,1)' d='M3.0598e-06 35C4.74968e-06 15.67 15.67 -4.74968e-06 35 -3.0598e-06C54.33 -1.36992e-06 70 15.67 70 35C70 54.33 54.33 70 35 70C15.67 70 1.36992e-06 54.33 3.0598e-06 35Z' fill='white' fill-opacity='0.8'/%3E%3Cpath transform='translate(70,0) scale(-1,1)' d='M37.332 43.3334L28.9987 35L37.332 26.6667' stroke='black' stroke-width='3.33333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E") 16 16, e-resize;
  right: 0;
}