.password {
  padding: 0 20px;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.password__header {
  font-size: 24px;
  color: #FAFAFA;
  letter-spacing: -0.48px;
  text-align: center;
  width: 75%;
  margin: 0 auto 7px;
}

.password__form {
  width: 100%;
  max-width: 312px;
  margin: 0 auto 100px;
  display: flex;
  align-items: center;
  position: relative;
}

.password__input {
  width: 100%;
  box-sizing: border-box;
  padding: 7px 57px 5px 12px;
  border-radius: 12px;
  font-size: 18px;
  font-weight: 600;
  color: #000000;
  line-height: 26px;
  border: 2px solid #000000;
}

.password--disabled .password__input {
  color: rgba(0, 0, 0, 0.3);
  border-color: rgba(0, 0, 0, 0.3);
}

.password__button {
  position: absolute;
  width: 51px;
  height: 100%;
  right: 0;
  background: none;
  outline: none;
  padding: 4px 14px 0;
  box-sizing: border-box;
}

.password__error {
  position: absolute;
  bottom: -40px;
  font-size: 15px;
  font-weight: 500;
  line-height: 38px;
  color: #FF3B30;
  text-align: center;
  width: 100%;
}