.page {
  padding: 54px 22px;
}

.page__texts {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin: 0 auto 54px;
}

.page__text-right, .page__text-left {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.16px;
  white-space: pre-wrap;
}

.page--white-text .page__text-right,
.page--white-text .page__text-left {
  color: #fff;
}

.page__images {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.page__image-wrapper {
  display: flex;
  justify-content: center;
}

.page__image {
  width: 100%;
  height: auto;
}

@media only screen and (min-width: 768px) {
  .page {
    padding: 120px 97px;
  }

  .page__texts {
    max-width: 939px;
    flex-direction: row;
    gap: 62px;
    margin-bottom: 100px;
  }

  .page__text-right,
  .page__text-left {
    font-size: 18px;
    letter-spacing: -0.18px;
  }

  .page__text-left {
    flex: 0 0 28%;
    width: 28%;
  }

  .page__images {
    gap: 100px;
  }

  .page__image {
    max-width: 100%;
    height: auto;
  }
}