.main {
  min-width: 320px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.main--yellow {
  background: rgba(211, 255, 85);
}

.main--lightgreen {
  background: rgba(223, 238, 205);
}

.main--orange {
  background: rgb(255, 198, 113);
}

.main--brown {
  background: rgb(136, 83, 61);
}

.main--black,
.main--dog {
  background: rgb(36, 36, 36);
}

.main--pink {
  background: rgb(255, 208, 208);
}

.main--white {
  background: rgb(241, 249, 255);
}

.main--dog {
  background: #fff;
  background-image: url('../../assets/images/NotWork/DogMobile.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
}

@media only screen and (min-width: 768px) {
  .main--dog {
    background-image: url('../../assets/images/NotWork/DogTablet.png');
  }
}

@media only screen and (min-width: 1200px) {
  .main--dog {
    background-image: url('../../assets/images/NotWork/DogDesktop.png');
  }
}

@supports (height: 100dvh) {
  .main {
    min-height: 100dvh;
  }
}