html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  background: transparent;
	box-sizing: border-box;
	display: block;
}

html, body {
  overscroll-behavior: none;
}

button,
a {
  cursor: pointer;
}

button,
input,
textarea {
  outline: none;
  border: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
textarea,
label {
  font-weight: normal;
}

body {
  font-family: "Denim", sans-serif;
}

#root {
  overflow: auto;
}

@font-face {
  font-family: "Denim";
  src: url("./assets/fonts/Denim-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Denim";
  src: url("./assets/fonts/Denim-Regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Denim";
  src: url("./assets/fonts/Denim-Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Denim";
  src: url("./assets/fonts/Denim-SemiBold.otf") format("opentype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Denim";
  src: url("./assets/fonts/Denim-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Denim";
  src: url("./assets/fonts/Denim-Heavy.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}